<template>
  <div>
    <div class="container-fluid" style="margin-top: 71px; position: relative" v-show="showBlock === 'planningResult'">
      <Plan ref="savePlanModal" @updatePlan="updatePlan" />
      <Toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
        :time="10000" @clearToast="clearToast" />
      <div>
        <ul class="breadcrumb ProximaNovaRegular">
          <li>
            <span>Model</span>
          </li>
          <li>
            <router-link to="/demo/digitalpoem/stats">
              <span>Sale Statistics</span>
            </router-link>
          </li>
          <li>
            <router-link to="/demo/digitalpoem/insights">
              <span>Future Planning</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold">Planning Results</li>
        </ul>
      </div>
      <div class="d-flex mt-4" :class="[expand ? 'justify-content-end' : 'justify-content-between']">
        <div class="w-100 d-flex" style="justify-content: space-between;">
          <div>
            <div class="d-none d-md-block card arrow-card" :class="[expand ? 'arrow-card-collapse' : '']"
              @click="toggleExpand">
              <i v-if="!expand" class="fas fa-arrow-left arrow"></i>
              <i v-else class="fas fa-arrow-right arrow-white"></i>
            </div>
          </div>
          <div>
            <w-button class="mx-2 btn-effect" @buttonClicked="$refs.savePlanModal.open('add')" :buttonText="'Save Plan'"
              style="width: auto;">
            </w-button>
            <w-button class="mx-2 btn-effect" @buttonClicked="showAgile" :buttonText="'Agile Planning'"
              style="width: auto;">
            </w-button>
            <w-button class="btn-effect-outline" @buttonClicked="gotoScenarioPlan" :buttonLoader="'none'"
              :buttonText="'Scenerio Planning'" style="width: auto;">
            </w-button>
          </div>
        </div>
      </div>

      <b-row>
        <b-col cols="12" md="4" :class="[expand ? 'd-none' : '']">
          <div class="card">
            <div class="card-body p-0">
              <div class="card-title">Prediction</div>
              <div class="mt-5">
                <w-tab :tabName="activePredictionTab" :tabs="predictionTab" @activeTab="changePredictionTab($event)">
                </w-tab>
              </div>
              <b-container fluid class="slide-parent">
                <SalesGrowth v-if="activePredictionTab === 'Revenue Based'" :sliderValue="sliderRevenueValue"
                  :data="revenueData" :bigRangeSize="true" :step="5" :handleColor="'#536dfe'"
                  @sliderChangeHandle="handleRevenueSlider" />
                <SalesGrowth v-if="activePredictionTab === 'Budget Based'" :sliderValue="sliderBudgetValue"
                  :data="budgetData" :bigRangeSize="true" @sliderChangeHandle="handleBudgetSlider" />
              </b-container>
            </div>
          </div>
          <div class="card mt-5 mb-5 mb-md-0">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-12">
                  <details class="">
                    <Summary class="Additional-details">Additional Details</Summary>
                    <b-container fluid>
                      <div v-for="(detail, index) in additionalDetails" :key="index"
                        class="row additonal-detail py-5 px-4" :class="[!detail.select ? 'border-top' : 'detail-bg']">
                        <b-col cols="10">
                          <div class="details-heading">{{ detail.name }}</div>
                          <div v-if="detail.select">
                            <label>Product</label>
                            <b-form-select v-model="detail.product" :options="detail.dropdownList"></b-form-select>
                            <label>Date</label>
                            <div>
                              <input type="date" :value="detail.date" class="date-input" />
                            </div>
                            <label>Value in numbers</label>
                            <b-form-input v-model="detail.value" type="number"></b-form-input>
                          </div>
                        </b-col>
                        <b-col cols="2">
                          <input :name="`checkbox-${index}`" type="checkbox" class="form-check-input"
                            :value="detail.select" @input="
                              updateCheckbox(index, $event.target.checked)
                            " />
                        </b-col>
                      </div>
                    </b-container>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" :md="expand ? 12 : 8">
          <div class="card mb-4">
            <div class="card-body p-0">
              <div class="card-title">Prediction</div>
              <b-container fluid class="wrapper-statistics">
                <b-row>
                  <b-col cols="12" sm="6" :md="expand ? 2 : 4" v-for="(data, index) in predictionData" :key="index"
                    class="item">
                    <div class="item-img" :class="[
                      index === 0
                        ? 'blue-pastel'
                        : index === 1
                          ? 'green-pastel'
                          : index === 2
                            ? 'pink-pastel'
                            : index === 3
                              ? 'red-pastel'
                              : index === 4
                                ? 'yellow-pastel'
                                : index === 5
                                  ? 'purple-pastel'
                                  : 'blue-pastel',
                    ]">
                      <!-- <i class="fas fa-chart-pie" style="font-size: 24px"></i> -->
                      <img :src="require(`@/assets/Icons/${data.name}.svg`)" :alt="data.name" height="25px" />
                    </div>

                    <div class="item-text" v-if="data.name === 'Variance'">
                      <div class="item-text__title">{{ data.name }}</div>
                      <div class="item-text__subtitle">{{ data.value }}%</div>
                    </div>
                    <div class="item-text" v-else>
                      <div class="item-text__title">{{ data.name }}</div>
                      <div class="item-text__subtitle">{{ data.value }}M</div>
                    </div>

                    <div v-if="
                      (!expand && (index + 1) % 3 !== 0) ||
                      (expand && (index + 1) % 6 !== 0)
                    " class="item-right-line"></div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body p-3">
              <ColumnChart :chartData="columnChartData" />
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body pt-2">
              <Digital :data="digitalExpandData" :stripData="digitalData" subtitle="Recommended Digital Mix"
                @getDataBreakdown="getDigitalBreakdown" total="" />
            </div>
          </div>

          <div class="card mb-4">
            <div class="card-body pt-2">
              <Content :barData="contentExpandData" :stripData="contentData" subtitle="Recommended Content Mix"
                @getDataBreakdown="getContentBreakdown" total="" />
            </div>
          </div>
          <div class="card">
            <div class="card-body p-3">
              <LineChart :chartData="lineChartData" />
            </div>
          </div>
        </b-col>
      </b-row>
      <page-loader v-if="countApi < countAllApi - 1"></page-loader>
    </div>
    <w-agile-planning-result @aglieBlock="showBlock = 'planningResult'" v-show="showBlock === 'agile'"
      :predictionRowData="predictionRowData" :row1Data="row1Data" :row2Data="row2Data" :row3Data="row3Data"
      :columnChartData="agileLevelOneData" :digitalData="digitalAgiledata"
      :digitalDropdownList="digitalAgileDropdownList" :digitalTitle="digitalAgileTitle"
      :expandDigitlData="digitalAgileExpandData" @clickedChart="clickedDigitalChart($event)"
      @changeDigitalDropdown="changeAgileDigitalDropdown($event)" :contentDropdownList="contentAgileDropdownList"
      :contentData="contentAgiledata" :contentTitle="contentAgileTitle" :expandContentData="contentAgileExpandData"
      @clickedContentChart="clickedContentChart($event)" @changeContentDropdown="changeAgileContentDropdown($event)">
    </w-agile-planning-result>
  </div>
</template>

<script>
import PageLoader from "@/widgets/PageLoader.vue";
import Tab from "@/components/Solutions/Tab.vue";
import SalesGrowth from "@/components/Solutions/SalesGrowth.vue";
import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";
import LineChart from "@/components/Chart/LineChart.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import Plan from "@/components/Modal/Plan.vue";
import Toast from "@/components/Toast/Toast.vue";
import Button from "@/components/Profile/Button.vue";
import { DigitalPoemServices } from "@/services/DigitalPoemServices.js";
const digitalPoemService = new DigitalPoemServices();
import AgilePlanningResult from "./AgilePlanningResults.vue";
export default {
  name: "PlanningResults",
  components: {
    "w-tab": Tab,
    "w-button": Button,
    SalesGrowth,
    Digital,
    Content,
    LineChart,
    ColumnChart,
    Plan,
    Toast,
    PageLoader,
    "w-agile-planning-result": AgilePlanningResult,
  },
  data() {
    return {
      digitalAgileExpandData: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      contentAgileExpandData: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      showBlock: "planningResult",
      agileLevelOneData: {
        data: [
          {
            name: "Recommended",
            data: [75, 100],
          },
          {
            name: "Planned",
            data: [45, 50],
          },
        ],
        title: {
          text: "Level 1 - Previous Vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Budget",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        xAxis: {
          categories: ["Digital", "Content"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffab40", "#ffdd7c"],
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 20,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      countApi: 0,
      market: "",
      expand: false,
      predictionTab: [
        {
          category: "Revenue Based",
        },
        // {
        //   category: "Budget Based",
        // },
      ],
      activePredictionTab: "Revenue Based",
      sliderRevenueValue: 16,
      revenueData: [{ name: "Expected Revenue", value: 621 }],
      sliderBudgetValue: 46,
      budgetData: [{ name: "Expected Budget", value: 621 }],
      digitalExpandData: [
        {
          name: "Video Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 10, 21),
              x2: Date.UTC(2014, 11, 2),
              y: 0,
              pointWidth: 16,
              pointPadding: 0,
              legendColor: "black",
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Engagement Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 2),
              x2: Date.UTC(2014, 11, 10),
              y: 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Lead Ads",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 10),
              x2: Date.UTC(2014, 11, 23),
              y: 2,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Click to website",
          pointWidth: 20,
          color: "red",
          data: [
            {
              x: Date.UTC(2014, 11, 23),
              x2: Date.UTC(2014, 11, 30),
              y: 3,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
      ],
      digitalData: [
        {
          label: "Google Analytics",
          value: 35,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 20,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 8,
          backgroundColor: "#148f3a",
        },
        {
          label: "Linkedin",
          value: 9,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 15,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 13,
          backgroundColor: "#08a0e9",
        },
      ],
      contentData: [
        {
          label: "Paid Media",
          value: 23,
          backgroundColor: "#85edff",
        },
        {
          label: "Owned Content",
          value: 77,
          backgroundColor: "#b5a9ff",
        },
      ],
      contentExpandData: {
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#8394b4",
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#fdbfff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [
          {
            name: "Direct Posts",
            data: [10],
            pointWidth: 16,
            yAxis: 1,
          },
          {
            name: "Product reviews",
            data: [31],
            pointWidth: 16,
            yAxis: 1,
          },
          {
            name: "Influencer mentions",
            data: [63],
            pointWidth: 16,
            yAxis: 1,
          },
          {
            name: "Interviews",
            data: [20],
            pointWidth: 16,
            yAxis: 1,
          },
        ],
      },
      predictionData: [
        {
          name: "Previous Revenue",
          value: "568",
        },
        {
          name: "Predicted Revenue",
          value: "768",
        },
        {
          name: "Variance",
          value: "268",
        },
        {
          name: "Previous Budget",
          value: "768",
        },
        {
          name: "Predicted Budget",
          value: "568",
        },
        {
          name: "Predicted Revenue",
          value: "268",
        },
      ],
      lineChartData: {
        colors: ["#050505", "#18ffff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function () {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
        series: [
          {
            data: [1, 2, 3, 3, 2, 1, 4, 5, 3, 7, 9, 10],
            marker: {
              symbol: "circle",
              radius: 10,
              // status: {
              //   hover: {
              //     enabled: true,
              //   },
              // },
            },
            type: "spline",
            name: "Actual",
          },
          {
            data: [3, 2, 1, 2, 1, 4, 1, 2, 3, 3, 9, 10],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "Predicted",
          },
        ],
        title: {
          text: "Media - Previous vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Recommended Budget Mix",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "sales growth %",
            style: {
              color: "#8394b4",
            },
            y: 12,
          },
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "August",
            "Sept",
            "October",
            "November",
            "December",
          ],
        },
      },
      columnChartData: {
        data: [
          {
            name: "Previous",
            data: [75, 100],
          },
          {
            name: "Predicted",
            data: [45, 50],
          },
        ],
        title: {
          text: "Level 1 - Previous Vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Budget",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        xAxis: {
          categories: ["Digital", "Content"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffab40", "#ffdd7c"],
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 20,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      additionalDetails: [
        {
          name: "Product",
          product: "",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          date: "",
          value: "",
        },
        {
          name: "Distribution",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
        {
          name: "Consumer Promo",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
        {
          name: "Advertising",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
      ],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      digitalThirdLevelData: [],
      contentThirdLevelData: [],
      contentThirdLevelAgileData: [],
      digitalThirdLevelAgileData: [],
      levelOneData: [],
      colors: [
        "#f77f00",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
        "#08a0e9",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
      ],
      budgetVariance: 0,
      predictionRowData: [],
      row1Data: [],
      row2Data: [],
      row3Data: [],
      digitalAgileDropdownList: [],
      digitalAgileTitle: {},
      digitalAgiledata: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedDigitalChart({
                    id: data.point.category,
                    text: data.point.category,
                  });
                },
              },
            },
          },
        },
        color: ["#e53935", "#00acc1"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      contentAgileDropdownList: [],
      contentAgileTitle: {},
      contentAgiledata: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedContentChart({
                    id: data.point.category,
                    text: data.point.category,
                  });
                },
              },
            },
          },
        },
        color: ["#e53935", "#00acc1"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
    };
  },
  mounted() { },
  methods: {
    changeAgileDigitalDropdown(data) {
      if (
        data === this.digitalAgileTitle ||
        data === this.digitalAgileTitle.text
      ) {
        return;
      }
      this.digitalAgileTitle = data;
      this.changeDigitalAgileData(data.text);
    },
    clickedDigitalChart(data) {
      this.digitalAgileTitle = data;
      console.log(data);
      this.changeDigitalAgileData(data.text);
    },
    changeContentAgileData(name) {
      var result = this.contentThirdLevelAgileData.find(
        (obj) => obj.title.toLowerCase() === name.toLowerCase()
      ).value;
      if (result.length !== 0) {
        this.contentAgileExpandData.data[0].data = [];
        this.contentAgileExpandData.data[1].data = [];
        this.contentAgileExpandData.xAxis.categories = [];
        for (var i = 0; i < result.length; i++) {
          this.contentAgileExpandData.data[0].data.push(result[i].previous);
          this.contentAgileExpandData.data[1].data.push(result[i].predicted);
          this.contentAgileExpandData.xAxis.categories.push(result[i].name);
        }
      }
    },
    changeDigitalAgileData(name) {
      var result = this.digitalThirdLevelAgileData.find(
        (obj) => obj.title.toLowerCase() === name.toLowerCase()
      ).value;
      if (result.length !== 0) {
        this.digitalAgileExpandData.data[0].data = [];
        this.digitalAgileExpandData.data[1].data = [];
        this.digitalAgileExpandData.xAxis.categories = [];
        for (var i = 0; i < result.length; i++) {
          this.digitalAgileExpandData.data[0].data.push(result[i].previous);
          this.digitalAgileExpandData.data[1].data.push(result[i].predicted);
          this.digitalAgileExpandData.xAxis.categories.push(result[i].name);
        }
      }
    },
    changeAgileContentDropdown(data) {
      if (
        data === this.contentAgileTitle ||
        data === this.contentAgileTitle.text
      ) {
        return;
      }
      this.contentAgileTitle = data;
      this.changeContentAgileData(data.text);
    },
    clickedContentChart(data) {
      this.contentAgileTitle = data;
      this.changeContentAgileData(data.text);
    },
    showAgile() {
      this.showBlock = "agile";
    },
    handleBudgetSlider(e) {
      this.sliderBudgetValue = e;
    },
    handleRevenueSlider(e) {
      if (e > 30) {
        return;
      }
      var data = this.predictionData.find(
        (obj1) => obj1.name === "Last year Revenue"
      ).value;
      var result = (data * e) / 100 + data;
      this.predictionData.find(
        (obj) => obj.name === "Expected Revenue"
      ).value = result;
      this.sliderRevenueValue = e;
      sessionStorage.setItem("edited", "notedited");
      sessionStorage.setItem("growthVal", e);
      this.predictionData.pop();
      this.predictionData.pop();
      this.predictionData.pop();
      this.predictionData.pop();
      this.predictionData.push({ name: "Variance", value: e });
      this.predictionData.push({
        name: "Previous Budget",
        value: sessionStorage.getItem("previousBudget"),
      });
      this.countApi = 1;
      this.getVariance();
    },
    getContentBreakdown(data) {
      var expandData;
      if (this.contentThirdLevelData.length === 0) return;
      expandData = this.contentThirdLevelData.find(
        (obj) => obj.title === data.label
      ).value;
      this.contentExpandData.series = [];
      for (var i = 0; expandData.length; i++) {
        if (i === expandData.length) {
          return;
        }
        this.contentExpandData.series.push({
          name: expandData[i].name,
          data: [expandData[i].value],
          pointWidth: 16,
          yAxis: 1,
        });
      }
    },
    getDigitalThirdLevelData(data) {
      digitalPoemService
        .getPredictedThirdLevel(
          data.label.toLowerCase(),
          this.market,
          data,
          "NotEdited",
          data.value
        )
        .then((res) => {
          this.digitalThirdLevelData.push({ title: data.label, value: res });
          this.getDigitalThirdLevelAgile(data.label);
          this.countApi++;
        })
        .catch(() => {
          let dummyData = [
            {
              "name": "Video Campaigns",
              "value": 52
            },
            {
              "name": "Display Campaigns",
              "value": 48
            }
          ]
          this.digitalThirdLevelData.push({ title: data.label, value: dummyData });
          this.getDigitalThirdLevelAgile(data.label);
          this.countApi++;
        });
    },
    getContentThirdLevelData(data) {
      digitalPoemService
        .getPredictedThirdLevel(
          data.label.toLowerCase(),
          this.market,
          data,
          "NotEdited",
          data.value
        )
        .then((res) => {
          this.contentThirdLevelData.push({ title: data.label, value: res });
          this.getContentThirdLevelAgile(data.label);
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getDigitalBreakdown(data) {
      var expandData;
      if (this.digitalThirdLevelData.length === 0) return;
      expandData = this.digitalThirdLevelData.find(
        (obj) => obj.title === data.label
      ).value;
      var count = 0;
      this.digitalExpandData = [];
      for (var i = 0; expandData.length; i++) {
        if (i === expandData.length) {
          return;
        }
        this.digitalExpandData.push({
          name: expandData[i].name,
          pointWidth: 20,
          data: [
            {
              x: count,
              x2: count + expandData[i].value,
              y: i + 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        });
        count += expandData[i].value;
      }
    },
    changePredictionTab(tabName) {
      this.activePredictionTab = tabName;
    },
    toggleExpand() {
      this.expand = !this.expand;
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    },
    updateCheckbox(index, val) {
      this.additionalDetails[index].select = val;
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    updatePlan() {
      this.toastData = {
        show: true,
        type: "success",
        message: "Yay, our system has saved your plan successfully",
      };
    },
    gotoScenarioPlan() {
      this.$router.push({
        name: "DigitalPoem-Scenario",
        params: {
          digitalData: this.digitalData,
          contentData: this.contentData,
          levelOneData: this.levelOneData,
          digitalThirdLevelData: this.digitalThirdLevelData,
          contentThirdLevelData: this.contentThirdLevelData,
        },
      });
    },
    getDigitalData() {
      var color = [
        "#6a1b9a",
        "#8e24aa",
        "#ab47bc",
        "#ba68c8",
        "#ce93d8",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
      ];
      digitalPoemService
        .getPredictedData({
          fileName: "digitalleveltwo",
          growthVal: this.sliderRevenueValue.toString(),
          market: this.market,
        })
        .then((res) => {
          this.digitalData = [];
          this.digitalThirdLevelAgileData = [];
          for (var i = 0; i < res.length; i++) {
            this.digitalData.push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: color[i],
            });
            this.getDigitalThirdLevelData({
              label: res[i].name,
              value: res[i].value,
            });
          }
          this.getDigitalAgile(res);
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getContentData() {
      digitalPoemService
        .getPredictedData({
          fileName: "contentleveltwo",
          growthVal: this.sliderRevenueValue.toString(),
          market: this.market,
        })
        .then((res) => {
          var color = ["#fb8c00", "#fbb44b"];
          this.contentData = [];
          this.contentThirdLevelAgileData = [];
          for (var i = 0; i < res.length; i++) {
            this.contentData.push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: color[i],
            });
            this.getContentThirdLevelData({
              label: res[i].name,
              value: res[i].value,
            });
          }
          this.getContentAgile(res);
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getMonthData() {
      digitalPoemService
        .getPredictedData({
          fileName: "mnthLvlRow1C2",
          growthVal: this.sliderRevenueValue.toString(),
          market: this.market,
        })
        .then((res) => {
          this.lineChartData.series[0].data = [];
          this.lineChartData.series[1].data = [];
          this.lineChartData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.lineChartData.series[0].data.push(res[i].previous);
            this.lineChartData.series[1].data.push(res[i].predicted);
            this.lineChartData.xAxis.categories.push(res[i].name);
          }
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getLevelOneData() {
      digitalPoemService
        .getPredictedData({
          fileName: "levelone",
          growthVal: this.sliderRevenueValue.toString(),
          market: this.market,
        })
        .then((res) => {
          this.levelOneData = res;
          this.columnChartData.data[0].data = [];
          this.columnChartData.data[1].data = [];
          this.columnChartData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.columnChartData.data[0].data.push(res[i].previous);
            this.columnChartData.data[1].data.push(res[i].predicted);
            this.columnChartData.xAxis.categories.push(res[i].name);
          }
          this.getLevelOneAgileData(res);
          this.getDigitalData();
          this.getContentData();
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getVariance() {
      digitalPoemService
        .getVariance(this.sliderRevenueValue, this.market)
        .then((res) => {
          this.budgetVariance = res.variance;
          this.predictionData.push({
            name: "Predicted Budget",
            value: res.predicted,
          });
          this.predictionData.push({ name: "Variance", value: res.variance });
          this.getLevelOneData();
          this.getAgileVariance();
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getVarianceAfterEdit() {
      digitalPoemService
        .getVarianceAfterEdit(
          this.budgetData[0].value,
          this.market,
          this.sliderRevenueValue
        )
        .then((res) => {
          this.predictionData.push({
            name: "Predicted Budget",
            value: Math.round(res.predicted * 100) / 100,
          });
          this.predictionData.push({ name: "Variance", value: res.variance });
          this.budgetVariance = res.variance;
          this.columnChartData.data[0].data = [];
          this.columnChartData.data[1].data = [];
          this.columnChartData.xAxis.categories = [];
          let levelone = this.$route.params.levelOneData;

          for (var i = 0; i < levelone.length; i++) {
            this.columnChartData.data[0].data.push(levelone[i].previous);
            this.columnChartData.data[1].data.push(levelone[i].predicted);
            this.columnChartData.xAxis.categories.push(levelone[i].name);
          }
          this.levelOneData = this.$route.params.levelOneData;
          this.digitalData = this.$route.params.digitalData;
          this.contentData = this.$route.params.contentData;
          this.contentThirdLevelData = this.$route.params.contentThirdLevelData;
          this.digitalThirdLevelData = this.$route.params.digitalThirdLevelData;
          this.countApi = 1;
          this.getAgileVariance();
          this.getLevelOneAgileData(this.levelOneData);
          var digitalFormatData = [];
          for (i = 0; i < this.digitalData.length; i++) {
            digitalFormatData.push({
              name: this.digitalData[i].label,
              value: this.digitalData[i].value,
            });
            if (i === this.digitalData.length - 1) {
              this.getDigitalAgile(digitalFormatData);
            }
          }
          var contentFormatData = [];
          for (i = 0; i < this.contentData.length; i++) {
            contentFormatData.push({
              name: this.contentData[i].label,
              value: this.contentData[i].value,
            });
            if (i === this.contentData.length - 1) {
              this.getContentAgile(contentFormatData);
            }
          }
          this.contentThirdLevelAgileData = [];
          for (i = 0; i < this.contentThirdLevelData.length; i++) {
            this.getContentThirdLevelAgile(this.contentThirdLevelData[i].title);
          }
          this.digitalThirdLevelAgileData = [];
          for (i = 0; i < this.digitalThirdLevelData.length; i++) {
            this.getDigitalThirdLevelAgile(this.digitalThirdLevelData[i].title);
          }
        })
        .catch(() => {
          this.countApi = 1;
        });
    },
    getAgileVariance() {
      var revenue = this.predictionData.find(
        (obj) => obj.name === "Expected Revenue"
      ).value;
      var predictedBudget = this.predictionData.find(
        (obj) => obj.name === "Predicted Budget"
      ).value;
      digitalPoemService
        .getAgilePlan(revenue, predictedBudget, this.budgetVariance)
        .then((res) => {
          this.predictionRowData = [];
          this.row1Data = [];
          this.row2Data = [];
          this.row3Data = [];
          this.predictionRowData.push({
            label: "Annual Target",
            value: revenue + " MN USD",
          });
          this.predictionRowData.push({
            label: "Yearly Budget",
            value: predictedBudget + " MN USD",
          });
          this.predictionRowData.push({
            label: "Growth",
            value: sessionStorage.getItem("growthVal") + "%",
          });
          this.row1Data.push({
            label: "M1 Target",
            value: res.monthlytarget + " MN USD",
            image: "click.svg",
          });
          this.row1Data.push({
            label: "M1 Revenue",
            value: res.monthlyachievement + " MN USD",
            image: "file-paper-fill.svg",
          });
          this.row1Data.push({
            label: "Variance",
            value: res.varianceffromtarget + "%",
          });

          this.row2Data.push({
            label: "M2 Target",
            value: res.m2target + " MN USD",
            image: "click.svg",
          });
          this.row2Data.push({
            label: "M2 Forecast",
            value: res.m2forecast + " MN USD",
            image: "file-paper-fill.svg",
          });
          this.row2Data.push({
            label: "Variance",
            value: res.varianceform2 + "%",
            image: "funds-fill.svg",
          });

          this.row3Data.push({
            label: "M2 Pl. Budget",
            value: res.m2pibudget + " MN USD",
            image: "click.svg",
          });
          this.row3Data.push({
            label: "M2 Rec. Budget",
            value: res.m2recBudget + " MN USD",
            image: "file-paper-fill.svg",
          });
          this.row3Data.push({
            label: "Variance",
            value: res.m2recvariance + "%",
            image: "funds-fill.svg",
          });
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getLevelOneAgileData(data) {
      digitalPoemService
        .getAgileChartData("levelone", this.market, data)
        .then((res) => {
          this.agileLevelOneData.data[0].data = [];
          this.agileLevelOneData.data[1].data = [];
          this.agileLevelOneData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.agileLevelOneData.data[0].data.push(res[i].previous);
            this.agileLevelOneData.data[1].data.push(res[i].predicted);
            this.agileLevelOneData.xAxis.categories.push(res[i].name);
          }
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getDigitalAgile(data) {
      digitalPoemService
        .getAgileChartData("digitalleveltwo", this.market, data)
        .then((res) => {
          this.digitalAgileDropdownList = [];
          this.digitalAgiledata.data[0].data = [];
          this.digitalAgiledata.data[1].data = [];
          this.digitalAgiledata.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.digitalAgiledata.data[0].data.push(res[i].previous);
            this.digitalAgiledata.data[1].data.push(res[i].predicted);
            this.digitalAgiledata.xAxis.categories.push(res[i].name);
            this.digitalAgileDropdownList.push({
              text: res[i].name,
              id: res[i].name,
              iconHTML: '<i class="fab fa-' + res[i].name + '"></i>',
            });
            if (i === res.length - 1) {
              this.digitalAgileTitle = this.digitalAgileDropdownList[0];
            }
          }
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getContentAgile(data) {
      digitalPoemService
        .getAgileChartData("content", this.market, data)
        .then((res) => {
          this.contentAgileDropdownList = [];
          this.contentAgiledata.data[0].data = [];
          this.contentAgiledata.data[1].data = [];
          this.contentAgiledata.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.contentAgiledata.data[0].data.push(res[i].previous);
            this.contentAgiledata.data[1].data.push(res[i].predicted);
            this.contentAgiledata.xAxis.categories.push(res[i].name);
            this.contentAgileDropdownList.push({
              text: res[i].name,
              id: res[i].name,
              iconHTML: '<i class="fab fa-' + res[i].name + '"></i>',
            });
            if (i === res.length - 1) {
              this.contentAgileTitle = this.contentAgileDropdownList[0];
            }
          }
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getContentThirdLevelAgile(name) {
      var data = this.contentThirdLevelData.find((obj) => obj.title === name)
        .value;
      digitalPoemService
        .getAgileChartData(name, this.market, data)
        .then((res) => {
          this.contentThirdLevelAgileData.push({ title: name, value: res });
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getDigitalThirdLevelAgile(name) {
      console.log(name);
      var data = this.digitalThirdLevelData.find((obj) => obj.title === name)
        .value;
      digitalPoemService
        .getAgileChartData(name, this.market, data)
        .then((res) => {
          this.digitalThirdLevelAgileData.push({ title: name, value: res });
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
  },
  created() {
    var data = JSON.parse(sessionStorage.getItem("expectedResult"));
    this.predictionData = [];
    for (var i = 0; i < data.length; i++) {
      this.predictionData.push(data[i]);
    }
    this.revenueData = [data.find((x) => x.name === "Expected Revenue")];
    this.predictionData.push({
      name: "Variance",
      value: sessionStorage.getItem("growthVal"),
    });
    this.predictionData.push({
      name: "Previous Budget",
      value: sessionStorage.getItem("previousBudget"),
    });
    this.budgetData = [data[1]];
    this.sliderRevenueValue = parseInt(sessionStorage.getItem("growthVal"));
    this.market = JSON.parse(sessionStorage.getItem("kpiMarket")).id;
    this.getMonthData();
    if (
      sessionStorage.getItem("edited") === "edited" &&
      this.$route.params.levelOneData !== undefined
    ) {
      this.getVarianceAfterEdit();
    } else {
      this.getVariance();
    }
  },
  computed: {
    countAllApi() {
      var total = 0;
      total += 5;
      total += this.digitalData.length;
      total += this.contentData.length;
      if (
        sessionStorage.getItem("edited") === "edited" &&
        this.$route.params.levelOneData !== undefined
      ) {
        return total - 1;
      } else {
        return total * 2 - 1;
      }
    },
  },
};
</script>
<style>
.slide-parent .rs-handle.rs-move {
  border: 11px solid #fff;
}
</style>

<style scoped>
.card {
  box-shadow: 0 0 8px 0 #c9ced5;
}

.card-title {
  margin: 24px 0px 0px 24px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.card-body {
  padding: 24px;
}

.arrow-card {
  width: 74px;
  height: 74px;
  padding: 25px;
  box-shadow: 0 0 8px 0 #c9ced5;
}

.arrow-card-collapse {
  background-color: black;
  left: 0;
  margin-top: 0px;
}

.arrow {
  font-size: 18px;
}

.arrow-white {
  color: #fff;
  font-size: 18px;
}

.btn-group {
  margin-bottom: 31px;
}

.btn-effect-outline {
  padding: 6px;
  border-radius: 5px;
  border: solid 2px #050505;
  font-family: ProximaNovaBold;
  font-size: medium;
  color: #222a37 !important;
  background-color: transparent !important;
}

.btn-effect-outline:hover {
  color: #fff !important;
}

.wrapper-statistics {
  padding: 40px 24px;
}

.item {
  display: flex;
  margin: 20px 0px;
  align-items: center;
}

/* .item:not(:nth-child(3n)):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
} */

.item-right-line {
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.blue-pastel {
  background: #ff8a80;
}

.green-pastel {
  background: #ff80ab;
}

.pink-pastel {
  background: #64ffda;
}

.red-pastel {
  background: #ffd740;
}

.yellow-pastel {
  background: #40c4ff;
}

.purple-pastel {
  background: #ea80fc;
}

.Additional-details {
  width: 259px;
  margin: 1px 986px 2px 0;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
  padding: 28px 24px;
}

.details-heading {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}

label {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
  margin-top: 37px;
}

input[type="number"] {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  background-color: #eff5ff;
}

input[type="date"] {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  width: 100%;
  background-color: #eff5ff;
}

select {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  padding-left: 0;
  background-color: #eff5ff;
}

option {
  color: #222a37;
  font-family: ProximaNovaRegular;
  font-size: 16px;
}

select:focus {
  border: none;
  border-bottom: 1px solid black;
  box-shadow: none;
  outline: none;
}

.detail-bg {
  background-color: #eff5ff;
}
</style>